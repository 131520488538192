import React, { useState } from 'react'
import FadeOut from './FadeOut'
import { useLoader } from '../store'
import LoadingBar from './LoadingBar'
import LoadingScreen from './LoadingScreen'
import PropTypes from 'prop-types'
import { stopMeasuringDownloadTime } from '../helper/google-analytics'

export default function Loader({ loading, done, content, loadingBar }) {
  const { state, doneAmount } = useLoader()
  const [mounted, setMounted] = useState(true)

  function unmountLoadingScreen() {
    setMounted(false)
    stopMeasuringDownloadTime()
  }

  return (
    <>
      {mounted && (
        <div
          style={{
            zIndex: 1002,
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
          }}
        >
          <FadeOut
            fade={state.isDone && !state.hasError}
            onFinished={unmountLoadingScreen}
            delay={500}
          >
            <LoadingScreen state={state} loading={loading} done={done} content={content} />
            {loadingBar && <LoadingBar amount={doneAmount} />}
          </FadeOut>
        </div>
      )}
    </>
  )
}

Loader.propTypes = {
  loading: PropTypes.element,
  done: PropTypes.element,
  content: PropTypes.element,
  loadingBar: PropTypes.bool,
}
