import devContent from './../dev.json'
// const LogPrefixMessage = 'Vault'
const LogsEnabled = devContent.VaultLogs

export function Log(title, msg) {
  if (LogsEnabled) {
    console.log('%c' + title, 'background: #c9e7ff; color: #212121', msg)
  }
}

export function LogError(title, msg) {
  if (LogsEnabled) {
    console.error('%c' + title, 'background: #ff7777; color: #fff', msg)
  }
}

export function LogWarn(title, msg) {
  if (LogsEnabled) {
    console.warn('%c' + title, 'background: #f7e46e; color: #0037c4', msg)
  }
}
