import { useEffect } from 'react'
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader'
import { PMREMGenerator, UnsignedByteType } from 'three'
import { useThree } from '@react-three/fiber'
import { useLoader } from '../store'

export default function Skybox({ hdri }) {
  const { gl, scene } = useThree()
  const { itemDoneById } = useLoader()

  useEffect(() => {
    let pmremGenerator = new PMREMGenerator(gl)
    pmremGenerator.compileEquirectangularShader()
    new RGBELoader().setDataType(UnsignedByteType).load(hdri, function (texture) {
      const envMap = pmremGenerator.fromEquirectangular(texture).texture
      scene.environment = envMap
      itemDoneById(5)
      texture.dispose()
      pmremGenerator.dispose()
    })
  }, [hdri])

  return null
}
