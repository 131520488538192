import React from 'react'

export default function PrivacyPolicy() {
  return (
    <>
      <div className="floater">
        <img className="logo" src="/legacy.png" alt="DistrictM" />
      </div>
      <div className="content">
        <h1>Privacy Policy</h1>
        <p>
          <b>TDW Technologies Private Limited</b>, operating and conducting business under its
          trademarked name <b>`&ldquo;`AutoVRse`&ldquo;`</b>, and its affiliates (hereinafter
          referred to variously as “AutoVRse“, “us`&ldquo;`, “our`&ldquo;` or “we“ based on the
          context) are dedicated to protecting the privacy rights of our users (“users“,
          “you`&ldquo;` or “your`&ldquo;`).
          <br />
          <br />
          This Privacy Policy (the “Policy”) describes the ways we collect, store, use, and manage
          the information, including personal information, that you may provide or we collect in
          connection with your use of our VR (Virtual Reality) game,{' '}
          <b>`&ldquo;`District M`&ldquo;`</b>, and/or all related software, the accompanying
          manual(s), related packaging and other written, files, electronic or on-line materials or
          documentation, any and all copies of the same, including any updates thereto
          (collectively, hereinafter referred to, unless repugnant to the context as `&ldquo;`the
          Game`&ldquo;` or `&ldquo;`the Games`&ldquo;`). Please note that the scope of this Policy
          is limited to information collected or received by AutoVRse through your use of{' '}
          <b>`&ldquo;`District M`&ldquo;`</b> or the Games. AutoVRse is not responsible for the
          actions of third-party people or companies, the content of their sites, the use of
          information you provide to them, or any products or services they may offer. Any link to
          those sites does not constitute our sponsorship, approval of, or affiliation with, those
          people or companies. By using the Games, you are expressing your agreement to this Policy
          and the processing of your data, including your personal information, in the manner
          provided in this Policy. If you do not agree to these terms, please do not use the Games.
          If you have questions or concerns about our privacy policy or practices, please contact us
          at
          <a href="mailto:privacy.game@autovrse.in">privacy.game@autovrse.in</a>.
        </p>
        <h2>Data Collection and Usage</h2>
        <p>
          AutoVRse may collect information as described below. Our primary goals in collecting and
          using any information is to improve the Game, your user experience and any other
          applications we may make in the future, contact you, conduct research and create reports
          for our internal use.
        </p>
        <h2>Collection and Use of Game Analytics Data</h2>
        <p>
          If you connect to the Internet when using the Games, we may receive information from
          hardware manufacturers or platform hosts (such as Valve, Oculus, Sony, HTC et al.) and may
          automatically collect certain information from your computer or gaming unit. This
          information may include, but is not limited to, user / display names, game scores, game
          achievements, game performance, locations connected from, IP address, and your usage of
          various game features. All information collected by us is intended to be{' '}
          <b>
            {' '}
            anonymous information that does not disclose your identity or constitute personal
            information, however, if you include personal information (such as your real name) in
            your user / display-name used with our platform hosts (such as Oculus, Steam et al.),
            then such personal information may automatically be transmitted and used as described
            herein.{' '}
          </b>{' '}
          The information that is collected and/or provided to us by hardware manufacturers or
          platform hosts is useful to us for troubleshooting, improving the Games and your user
          experience, game statistics, performance et al. helps us understand usage trends. This
          information may be associated with your IP address or user ID for the purpose of providing
          you services or support in connection with the Games. Games do use third party services
          that may independently collect information that can be personally identifiable. Link to
          privacy policy and/or objectives of such third party service providers used by our Games
          are as follows:{' '}
        </p>
        <ul>
          <li>
            <a href="https://policies.google.com/privacy">Google Play Services</a>
          </li>
          <li>
            <a href="https://unity3d.com/legal/privacy-policy">Unity</a>
          </li>
          <li>
            <a href="https://gameanalytics.com/privacy/">GameAnalytics</a>
          </li>
          <li>
            <a href="https://developer.oculus.com/documentation/unity/ps-platform-intro/">
              Oculus Platform Solutions
            </a>
          </li>
          <li>
            <a href="https://www.oculus.com/legal/privacy-policy/">Oculus Privacy Policy</a>
          </li>
        </ul>
        <p>
          Various services on Oculus Platform may be used by us to track user-performance in the
          Games, and enhance the overall user experience, including but not limited to displaying
          high-scores on a leaderboard (which would also display the user’s Oculus username /
          display name), cloud-storage (which may store the user’s game progress, achievements,
          play-time, other in-game activities / actions on a remote server) et al. The cloud-storage
          services will primarily help enhance user-experience when the user logs in to their Oculus
          account across different hardware / devices, by saving their game progress and ensuring
          continuity in game experience.
          <br />
          <br />
          Various services of the GameAnalytics SDK are used for tracking specific user parameters,
          properties and in-game performance, including but not limited to Scoring Data (Level
          Score, Highest Combo, Lives Left, Pickup Count et al.) and also User ID / Identifier (to
          track if the user is a new / first-time user), country of origin, session length, number
          of attempts and / or score at particular levels etc. All these data-points are collected
          anonymously by GameAnalytics, and AutoVRse does not seek to personally identify any user
          specifically using these data points.
        </p>
        <h2>Customer Service</h2>
        <p>
          We may collect your email address when you contact our customer service group and we may
          use that email address to contact you about your gaming experience with AutoVRse and
          notify you about company news and promotions. If you no longer wish to receive certain
          email notifications you may opt-out at any time by following the unsubscribe link located
          at the bottom of each communication or by emailing us at{' '}
          <a href="mailto:privacy.game@autovrse.in">privacy.game@autovrse.in</a>
        </p>
        <h2>Game Updates</h2>
        <p>
          We may occasionally send you game updates, patches and related push notifications that may
          be of importance to you. If you do not wish to receive these types of updates, patches and
          notifications you should discontinue use of the Games or disconnect your computer or
          device from the Internet
        </p>
        <h2>How We Use Information</h2>
        <p>
          We may use information collected through the Games or provided to us by hardware
          manufacturers and platform hosts for the purposes described in this Policy or disclosed to
          you in connection with the Games. For example, we may use your information to:
        </p>
        <ul>
          <li>operate and improve the Games and future games we may make</li>
          <li>
            understand you and your preferences to enhance your experience and enjoyment using the
            Games
          </li>
          <li>respond to your comments and questions and provide customer service</li>
          <li>provide and deliver products and services you request</li>
          <li>
            send you related information, including confirmations, technical notices, updates,
            security alerts, and support and administrative messages
          </li>
          <li>
            link or combine it with other information we get from third parties and other users of
            the Games, to help understand your preferences and provide you with better services.
          </li>
        </ul>
        <h2>Disclosure of Your information</h2>
        <p>
          AutoVRse does not share your personal information (to the extent it is in possession of
          any) except as approved by you or as described below:
          <ul>
            <li>
              We may engage other companies and individuals to perform services on our behalf.
              Example of these services include analyzing data and providing customer support. These
              agents and service providers may have access to your personal information in
              connection with the performance of services for AutoVRse
            </li>

            <li>
              We may release your information as permitted by law, such as to comply with a
              subpoena, or when we believe that release is appropriate to comply with the law;
              investigate fraud, respond to a government request, enforce or apply our rights; or
              protect the rights, property, or safety of us or our users, or others. This includes
              exchanging information with other companies and organizations for fraud protection
            </li>

            <li>
              We may share your information in connection with any merger, sale of our assets, or a
              financing or acquisition of all or a portion of our business to another company. You
              will be notified via a notice on our site of any change in ownership of AutoVRse or
              our business
            </li>

            <li>
              We may share aggregate or anonymous information about you with hardware manufacturers,
              platform hosts, publishers, business partners, and other third parties, to the extent
              permitted by applicable law.
            </li>
          </ul>
        </p>
        <h2>Changes to the Policy</h2>
        <p>
          We may update this privacy policy to reflect changes to our information practices. If we
          make any material changes we will notify you by email (sent to the e-mail address
          specified in your account) or by means of a notice on this Site prior to the change
          becoming effective. We encourage you to periodically review this page for the latest
          information on our privacy practices.
        </p>
        <h2>Security</h2>
        <p>
          AutoVRse takes reasonable measures to protect your information from unauthorized access or
          against loss, misuse or alteration by third parties. Although we make good faith efforts
          to store the information collected on in relation to the Games in a secure operating
          environment that is not available to the public, we cannot guarantee the absolute security
          of that information during its transmission or its storage on our systems. Further, while
          we attempt to ensure the integrity and security of our network and systems, we cannot
          guarantee that our security measures will prevent third-party `&ldquo;`hackers`&ldquo;`
          from illegally obtaining access to this information. We do not warrant or represent that
          your information will be protected against, loss, misuse, or alteration by third parties.
          No method of transmission over the Internet, or method of electronic storage, is 100%
          secure, however. Therefore, we cannot guarantee its absolute security.
        </p>
        <h2>Access and Accuracy</h2>
        <p>
          AutoVRse will use commercially reasonable efforts to provide access to your personally
          identifiable information (to the extent we are in possession of any) if you request such
          access in writing submitted to{' '}
          <a href="mailto:privacy.game@autovrse.in">privacy.game@autovrse.in</a>
          Unless otherwise required under applicable law, AutoVRse reserves the right to deny access
          to your personally identifiable information: 1. when such denial of access is required by
          law; 2. when granting you access is reasonably likely to negatively impact other people’s
          privacy; 3. when such access is cost prohibitive; or 4. when such requests are frivolous
          or made in bad faith. If you believe that the personally identifiable information
          maintained by AutoVRse about you is inaccurate or incomplete, you may notify AutoVRse in
          writing describing in details any inaccuracies or omissions, submitted to{' '}
          <a href="mailto:privacy.game@autovrse.in">privacy.game@autovrse.in</a>. Following receipt
          of such a properly submitted written notice, AutoVRse will, within a reasonable time
          period, use commercially reasonable efforts to, in its sole discretion, either: (a) amend
          or correct your personally identifiable information to reflect corrected or additional
          information provided by you, or (b) in connection with your personally identifiable
          information, make note of any claimed inaccuracies or omissions reflected in the notice
          submitted by you.
        </p>
        <h2>Data Retention & Deletion</h2>
        <p>
          The data and information that we collect will be stored and maintained by AutoVRse until
          you instruct us otherwise, or, in our sole discretion, for the longer of: (a) for so long
          as is necessary or appropriate to carry out the purpose(s) for which such information was
          collected and (b) for so long as we are required to maintain such information by law or
          other applicable rules or regulations and your consent to such purposes remains valid
          after termination of our relationship with you.
        </p>
        <p>
          For the purpose of data deletion, if you would like to terminate your relationship with
          us, and have deleted the Game and any other applications from your devices, and in so
          doing, are also keen on withdrawing your consent for the use of your information with us,
          you may contact us at{' '}
          <a href="mailto:privacy.game@autovrse.in">privacy.game@autovrse.in</a>. We will delete
          such information from our files within a reasonable time.
        </p>
        <h2>Our Policy Regarding Children</h2>
        <p>
          AutoVRse recognizes the privacy interests of children and we encourage parents and
          guardians to take an active role in their children’s use of the Games. This Games is not
          intended for children under the age of 13. AutoVRse does not target its services or the
          Games to children under the age of 13. AutoVRse does not knowingly collect personally
          identifiable information from children under the age of 13. If a parent or guardian
          becomes aware that his or her child has provided us with information without their
          consent, he or she should contact us at
          <a href="mailto:privacy.game@autovrse.in">privacy.game@autovrse.in</a>. We will delete
          such information from our files within a reasonable time.
        </p>
        <h2>International Transfer</h2>
        <p>
          We may transfer information that we collect about you to affiliated entities, or to other
          third parties across borders and from your country or jurisdiction to other countries or
          jurisdictions around the world. Please note that these countries and jurisdictions may not
          have the same data protection laws as your own jurisdiction, and you consent to the
          transfer of information to the U.S. and the use and disclosure of information about you,
          including personal information, as described in this Policy.
        </p>
        <h2>Contact Information</h2>
        <p>
          TDW Technologies Private Limited.
          <br />
          Attn: `&ldquo;`AutoVRse`&ldquo;` / Game Studio Privacy Coordinator
          <br />
          400, 15th Cross, 5th Main, Sector 6, HSR Layout, Bengaluru, KA 560102, India
          <br />
          <a href="mailto:privacy.game@autovrse.in">privacy.game@autovrse.in</a>
        </p>
      </div>
    </>
  )
}
