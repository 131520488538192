import create from 'zustand'
import { TWEEN } from 'three/examples/jsm/libs/tween.module.min'
import devContent from './dev.json'
import { Log, LogError } from './core/CustomLogger'

export const useTempStore = create((set) => ({
  currentValue: 0,
  setCurrentValue: (index) => set(() => ({ currentValue: index })),
}))

// 0 - box1 clicked
// 1 - box2 clicked
// 2 - box3 clicked
function isIdInItems(items, id) {
  for (var item in items) {
    if (items[item].id == id) return items[item]
  }
  return null
}

export const useCustomScroll = create((set) => ({
  requestedValue: 0,
  isDone: true,
  setRequestedValue: (value) => set({ requestedValue: value, isDone: false }),
  scrollDone: () => set({ isDone: true }),
}))

export const useLoader = create((set, get) => ({
  state: { isLoading: false, isDone: false, hasError: false },
  items: devContent.Loader.items,
  doneItems: [],
  doneAmount: 0,
  setDoneAmount: (amount) => set({ doneAmount: amount }),
  hasFinished: () => set({ state: { isLoading: false, isDone: true, hasError: false } }),
  hasError: () => set({ state: { isLoading: false, isDone: true, hasError: true } }),
  startLoading: () => {
    console.time('LoaderTime')
    set({ state: { isLoading: true, isDone: false, hasError: false } })
  },
  itemDoneById: (id) => {
    let state = get().state
    if (state.isLoading) {
      let itemsTemp = get().items
      let item = isIdInItems(itemsTemp, id)
      if (item != null) {
        let doneItemsTemp = get().doneItems
        let alreadyDone = isIdInItems(doneItemsTemp, id)
        if (!alreadyDone) {
          Log('Loader:Item Loaded', item)
          doneItemsTemp.push(item)
          get().setDoneAmount((doneItemsTemp.length / itemsTemp.length) * 100)
          if (doneItemsTemp.length == itemsTemp.length) {
            console.timeEnd('LoaderTime')
            get().hasFinished()
          }
        } else {
          LogError(
            'Loader:Error',
            'This loader "' +
              item.name +
              '" was already done, please check how your code is reporting the load'
          )
          get().hasError()
        }
      } else {
        LogError(
          'Loader:Error',
          'The loader with id: ' +
            id +
            ' was not found in the registered list of loaders, please make sure your loader is present in framework/panorama-viewer/dev.json'
        )
        get().hasError()
      }
    } else {
      LogError('Loader:Not Initialized/ Suspended', 'please call startLoading() to begin tracking')
    }
  },
  itemDoneByName: (name) => {
    console.log(name)
  },
}))

export const useTween = create((set) => ({
  activeTweenPoint: null,
  tweenActive: false,

  tweenDuration: 2000,
  tweenInterpolation: TWEEN.Easing.Quadratic.InOut,
  tweenName: 'default',

  startTween: (
    tweenPoint,
    tweenName,
    tweenDuration = 3200,
    interp = TWEEN.Easing.Quadratic.InOut
  ) => {
    set(() => ({
      activeTweenPoint: tweenPoint,
      tweenActive: true,
      tweenDuration: tweenDuration,
      tweenInterpolation: interp,
      tweenName: tweenName,
    }))
  },

  tweenFinished: () => {
    set(() => ({
      tweenActive: false,
    }))
  },
}))
