import { Html, Plane, useScroll } from '@react-three/drei'
import React, { useEffect, useRef, useState } from 'react'
import { sRGBEncoding } from 'three'
import PropTypes from 'prop-types'
import { useLoader } from '../store'
import { isIOS } from 'react-device-detect'
import { useFrame } from '@react-three/fiber'
import { useSpring } from '@react-spring/core'
import { animated as a } from '@react-spring/web'
import { css, StyleSheet } from 'aphrodite'
import { content } from '../content'

let videoLoaded = false

export function VideoHTMLTexture() {
  const { itemDoneById } = useLoader()
  const data = useScroll()
  const htmlRef = useRef()

  const [visible, setVisible] = useState(false)

  const style = useSpring({
    opacity: visible ? 1 : 0,
  })

  useEffect(() => {
    console.log(htmlRef.current)
    // htmlRef.current.style.pointerEvents = 'none'
    // htmlRef.current.children[0].style.pointerEvents = 'none'
    // htmlRef.current.children[0].children[0].style.pointerEvents = 'none'
  }, [htmlRef])

  useFrame(() => {
    console.log(data.offset)
    if (data.offset < 0.284 || data.offset > 0.86) {
      if (!visible) setVisible(true)
    } else if (visible) setVisible(false)
  })
  return (
    <>
      <group position={[-1.5, 0.54, 0]} scale={0.05} dispose={null}>
        <Html ref={htmlRef} transform zIndexRange={[0, 100]}>
          <a.div style={style} className={css(styles.container)}>
            <div style={{ minHeight: 700, backgroundColor: 'red' }}>
              <video
                src="/video/header.mp4"
                loop
                muted
                autoPlay
                playsInline
                className={css(styles.video)}
                onCanPlayThrough={() => {
                  if (!videoLoaded) {
                    videoLoaded = true
                    itemDoneById(6)
                  }
                }}
              />
            </div>
          </a.div>
        </Html>
      </group>
      <group position={[-0.6, -0.53, 0]} scale={0.05} dispose={null}>
        <Html ref={htmlRef} transform zIndexRange={[0, 100]}>
          <a.div style={style} className={css(styles.container)}>
            <div style={{ width: '128vw' }}>
              <div className={css(styles.text)}>{content.header}</div>
            </div>
          </a.div>
        </Html>
      </group>
    </>
  )
}

const styles = StyleSheet.create({
  container: {
    pointerEvents: 'none',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'blue',
  },
  video: {
    height: 770,
  },
  text: {
    padding: 5,
    fontSize: 64,
    color: '#2E5573d1',
    fontFamily: 'Open Sans',
    fontWeight: 700,
  },
})

export function VideoTexture() {
  const { itemDoneById } = useLoader()
  const [video] = useState(() =>
    Object.assign(document.createElement('video'), {
      src: '/video/header.mp4',
      crossOrigin: 'Anonymous',
      autoplay: true,
      loop: true,
      muted: true,
      playsInline: true,
      oncanplaythrough: () => {
        if (!videoLoaded) {
          videoLoaded = true
          itemDoneById(6)
        }
      },
    })
  )
  useEffect(() => {
    if (!isIOS) video.play()
  }, [video])
  return (
    <>
      <Plane position={[0.015, 0.07, -0.02]} scale={[1.28 * 2.38, 0.4 * 2.38, 0]}>
        <meshBasicMaterial toneMapped={false}>
          <videoTexture attach="map" args={[video]} encoding={sRGBEncoding} />
        </meshBasicMaterial>
      </Plane>
    </>
  )
}

VideoTexture.propTypes = {
  clicked: PropTypes.func,
}
