import { css, StyleSheet } from 'aphrodite'
import React from 'react'
import { useCustomScroll } from '../store'

export default function Branding() {
  const { setRequestedValue } = useCustomScroll()
  function onClick() {
    console.log('On Click')
    setRequestedValue(0)
  }
  return (
    <div className={css(styles.container)} onClick={onClick}>
      <img className={css(styles.logo)} src="/logo.webp" alt="District M" />
    </div>
  )
}

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    pointerEvents: 'all',
    top: 0,
    left: 0,
    zIndex: 1001,
    padding: '2vw',
  },
  logo: {
    width: '15vh',
    '@media (min-width: 576px)': {
      width: '14vw',
    },
    '@media (min-width: 768px)': {
      width: '15vh',
    },
    '@media (min-width: 1200px)': {
      width: '10vw',
    },
  },
})
