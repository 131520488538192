import { Html, useScroll } from '@react-three/drei'
import { useFrame } from '@react-three/fiber'
import React, { useRef } from 'react'
import { MathUtils } from 'three'

export default function WebsiteFooter() {
  const divRef = useRef()
  const data = useScroll()

  useFrame(() => {
    if (data.scroll.current < 0.95) {
      divRef.current.style.opacity = MathUtils.lerp(divRef.current.style.opacity, 0, 0.1)
    } else {
      divRef.current.style.opacity = MathUtils.lerp(divRef.current.style.opacity, 1, 0.05)
    }
  })

  return (
    <group position={[0.55, -1.5, 0]} scale={0.05}>
      <Html transform style={{ pointerEvents: 'none' }} zIndexRange={[1, 10]}>
        <div
          ref={divRef}
          style={{
            width: 700,
            pointerEvents: 'none',
            color: '#212121',
            fontSize: 32,
            textAlign: 'center',
          }}
        >
          <div
            style={{ float: 'left', marginLeft: 70, pointerEvents: 'all', cursor: 'pointer' }}
            onClick={() => (location.href = '/privacy')}
          >
            Privacy Policy
          </div>
          <div>© 2022 AutoVRse</div>
        </div>
      </Html>
    </group>
  )
}
