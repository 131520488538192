/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef, useState } from 'react'
import { animated } from '@react-spring/three'
import { useSpring } from '@react-spring/core'
import { FrontSide } from 'three'
import PropTypes from 'prop-types'

export default function Button3D({
  data,
  color,
  emissive,
  display,
  nodes,
  materials,
  onClick,
  position,
  offset = 0,
}) {
  const group = useRef()
  const [click, setClick] = useState(false)
  const [hover, setHover] = useState(false)

  const springAnim = useSpring({
    position: click ? [0, 0, -0.05] : [0, 0, 0],
    emissive: hover || click ? emissive : '#000000',
  })

  function onButtonClick() {
    if (onClick != null) onClick(data)
    setClick(true)
    setTimeout(() => {
      setClick(false)
    }, 250)
  }

  function onHover(stat) {
    setHover(stat)
    document.body.style.cursor = stat ? 'pointer' : 'default'
  }

  return (
    <group ref={group} dispose={null}>
      <group>
        <animated.group
          position={springAnim.position}
          onClick={onButtonClick}
          onPointerOver={() => onHover(true)}
          onPointerOut={() => onHover(false)}
        >
          <group position-z={offset}>
            <mesh
              position={position}
              scale={[0.11865, 0.12554, 0.0677]}
              geometry={nodes.Button.geometry}
            >
              <animated.meshStandardMaterial
                attach="material"
                {...materials['Material.056']}
                color={color}
                emissive={springAnim.emissive}
                side={FrontSide}
                metalness={0.2}
                roughness={0.3}
              />
            </mesh>
            <group position={[0, 0, -0.04]}>
              <primitive object={display.scene} dispose={null} />
            </group>
          </group>
        </animated.group>
      </group>
    </group>
  )
}

Button3D.propTypes = {
  onClick: PropTypes.func,
  nodes: PropTypes.object,
  materials: PropTypes.object,
  position: PropTypes.array,
  data: PropTypes.object,
  display: PropTypes.object,
  color: PropTypes.string,
  emissive: PropTypes.string,
  offset: PropTypes.number,
}
