import './App.css'
import { Canvas } from '@react-three/fiber'
import Skybox from './core/Skybox'
import Hud from './context/starter/Hud'
import { Suspense } from 'react'
import { Perf } from 'r3f-perf'
import { gaDeveloper, logEnabled } from './constants'
import VendingMachine from './context/VendingMachine'
import CameraMovements from './context/CameraMovements'
import { AdaptiveDpr, ScrollControls } from '@react-three/drei'
import WebHud from './context/WebHud'
import VendingMachineButtons from './context/VendingMachineButtons'
import SplashScreen from './core/SplashScreen'
import WebsiteHeader from './core/WebsiteHeader'
import FAQSection from './core/FAQSection'
import CustomScrolls from './context/CustomScrolls'
import { setDeveloperStatus } from './helper/google-analytics'
import Branding from './core/Branding'
import Toys from './context/Toys'
import { Route, Switch } from 'wouter'
import PrivacyPolicy from './context/PrivacyPolicy'
import NotFound from './context/NotFound'
// import CustomSAO from './context/CustomSAO'
// import Grading from './context/Grading'
// import { activateGA } from './helper/google-analytics'
// activateGA(gaConfig.code)
// Styleguide - https://github.com/airbnb/javascript - MUST READ
// Vault v1.0
// - Added eslint enforcing
// - Added prettier enforcing

// Contact Admin to enable CI/CD on your branch

// Use Google Chrome & Firefox simultaneously for any testing

if (gaDeveloper) setDeveloperStatus()

function App() {
  return (
    <>
      <Switch>
        <Route path="/privacy">
          <PrivacyPolicy />
        </Route>
        <Route path="/">
          <>
            <Canvas flat dpr={[1, 1.1]} performance={{ min: 0.65, max: 1.1 }}>
              {logEnabled && <Perf />}

              <ScrollControls regress pages={2} distance={3} damping={9}>
                <CameraMovements />
                <CustomScrolls />
              </ScrollControls>
              <WebsiteHeader />
              <VendingMachine />
              <Toys />
              <VendingMachineButtons />
              <FAQSection />
              <AdaptiveDpr />

              <Suspense fallback={null}>
                {/* <Grading /> */}
                {/* <Environment preset="warehouse" /> */}
                <Skybox hdri="/skybox/sample.hdr" />
              </Suspense>
              {/* <ambientLight intensity={0.3} color={'white'} /> */}
              {/* <pointLight position={[1, 3, 5]} intensity={1} color="#cecece" /> */}
              {/* <CustomSAO /> */}
            </Canvas>
            <SplashScreen />
            <WebHud />
            <Hud enabled={false} logEnabled={logEnabled} />
            <Branding />
          </>
        </Route>
        <Route path="/:rest">
          <NotFound />
        </Route>
      </Switch>
    </>
  )
}

export default App
