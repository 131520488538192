import React, { useEffect, useState } from 'react'
import { useSpring } from '@react-spring/core'
import { animated } from '@react-spring/web'
import PropTypes from 'prop-types'

export default function FadeOut({ children, fade, onFinished, delay }) {
  const [shouldFade, setFade] = useState(false)
  const animation = useSpring({
    opacity: shouldFade ? 0 : 1,
    onRest: (d) => {
      if (d.value.opacity == 0 && d.finished) {
        onFinished()
      }
    },
  })

  useEffect(() => {
    if (delay != null) {
      setTimeout(() => {
        setFade(fade)
      }, delay)
    } else setFade(fade)
  }, [fade])
  return <animated.div style={animation}>{children}</animated.div>
}

FadeOut.propTypes = {
  children: PropTypes.array,
  fade: PropTypes.bool,
  onFinished: PropTypes.func,
  delay: PropTypes.number,
}
