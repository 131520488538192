import { useScroll } from '@react-three/drei'
import { useFrame } from '@react-three/fiber'
import { useEffect, useRef, useState } from 'react'
import { MathUtils } from 'three'
import { colors_physical } from '../constants'
import { useCustomScroll } from '../store'
import WebsiteFooter from './WebsiteFooter'

let scrolling = false
let lightAlreadyOn = false

function CustomScrolls() {
  const fogRef = useRef()
  const data = useScroll()
  const light = useRef()
  const customScroller = useCustomScroll()
  const [req, setReq] = useState(0)

  useEffect(() => {
    if (!customScroller.isDone) setReq(customScroller.requestedValue)
  }, [customScroller])

  useEffect(() => {
    if (!customScroller.isDone) {
      data.el.scrollTo(0, req * window.innerHeight * 6)
      scrolling = true
    }
  }, [req])

  useFrame(() => {
    if (scrolling) {
      if (data.el.scrollTop == req * window.innerHeight * 6) {
        scrolling = false
        setReq(0)
        customScroller.scrollDone()
      }
    }
    fogRef.current.far = MathUtils.lerp(30, 12, data.offset)
    if (light.current != null) {
      if (data.offset < 0.05 && !lightAlreadyOn) {
        light.current.intensity = 0
      } else if (data.offset < 0.18 && !lightAlreadyOn) {
        let rn = Math.random()
        if (rn > 0.4) {
          light.current.intensity = MathUtils.lerp(light.current.intensity, 2, 0.8)
        } else light.current.intensity = MathUtils.lerp(light.current.intensity, 0, 0.8)
      } else if (data.offset > 0.36612) {
        light.current.intensity = MathUtils.lerp(light.current.intensity, 0, 0.05)
      } else {
        lightAlreadyOn = true
        light.current.intensity = MathUtils.lerp(light.current.intensity, 5, 0.3)
      }
    }
  })

  return (
    <>
      <fog ref={fogRef} attach="fog" args={[colors_physical.fog.color, 5, 25]} />
      <pointLight
        ref={light}
        position={[0, 3.2, -0.75]}
        intensity={0}
        color={colors_physical.vendingMachineLight.color}
      />
      <WebsiteFooter />
    </>
  )
}

export default CustomScrolls
