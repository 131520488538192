import { PerspectiveCamera, useAnimations, useGLTF, useScroll } from '@react-three/drei'
import React, { Suspense, useEffect, useRef, useState } from 'react'
// import ModelCamera from './../model/camera.gltf'
import { useFrame } from '@react-three/fiber'
import { LoopOnce, MathUtils } from 'three'
import { isMobile } from 'react-device-detect'
import { useLoader } from '../store'
import { useSpring } from '@react-spring/core'
import { animated as a } from '@react-spring/three'
import { sendEvent } from '../helper/google-analytics'

let previousTime = 0
let tmo = null

export default function CameraMovements() {
  return (
    <Suspense fallback={null}>
      <CameraMovementsLogic />
    </Suspense>
  )
}

// let scrolling = false
export function CameraMovementsLogic() {
  const ref = useRef()
  const cam = useRef()
  const { itemDoneById } = useLoader()
  const { nodes, animations } = useGLTF(
    isMobile ? '/model/MobileCamera.gltf' : '/model/Camera.gltf'
  )
  const { actions } = useAnimations(animations, ref)
  const data = useScroll()

  const [preLoad, setPreLoad] = useState(false)
  const [buttonActive, setButtonActive] = useState([false, false, false, false])
  const springAnimation = useSpring({
    scale: buttonActive[0] ? 5.2 : 4.82,
    position: buttonActive[0] ? [0.31, 0.27, -0.25] : [0.31, 0.27, -0.2],
    scale1: buttonActive[1] ? [8.41 * 1.1, 30.2 * 1.1, 8.43 * 1.1] : [8.41, 30.2, 8.43],
    position1: buttonActive[1] ? [-0.32, 0.31, -0.25] : [-0.32, 0.31, -0.19],
    scale2: buttonActive[2] ? 0.38 : 0.34,
    position2: buttonActive[2] ? [0.94, 0.3, -0.28] : [0.94, 0.3, -0.18],
    scale3: buttonActive[3] ? [28.13 * 1.1, 28.24 * 1.1, 28.24 * 1.1] : [28.13, 28.24, 28.24],
    position3: buttonActive[3] ? [-0.93, 0.31, -0.24] : [-0.93, 0.31, -0.18],
  })

  function onHoverEnter(index) {
    if (isMobile) return
    clearTimeout(tmo)
    document.body.style.cursor = 'pointer'
    setButtonState(index, true)
  }

  function onHoverExit(index) {
    if (isMobile) return
    clearTimeout(tmo)
    document.body.style.cursor = 'default'
    tmo = setTimeout(() => {
      setButtonState(index, false)
    }, 500)
  }

  function setButtonState(index, state) {
    let temp = []
    for (let i = 0; i < 4; i++) {
      if (i == index) temp[i] = state
      else temp[i] = false
    }
    setButtonActive(temp)
  }

  function onClick(index) {
    if (isMobile) {
      if (buttonActive[index]) {
        clickResult(index)
      } else {
        setButtonState(index, true)
      }
    } else clickResult(index)
  }

  function clickResult(index) {
    console.log(index)
    switch (index) {
      case 0:
        sendEvent('click', 'social-button', 'instagram')
        break
      case 1:
        sendEvent('click', 'social-button', 'tik-tok')
        break
      case 2:
        sendEvent('click', 'social-button', 'youtube')
        break
      case 3:
        sendEvent('click', 'social-button', 'twitter')
        break
    }
  }

  useFrame((e) => {
    let deltaTime = e.clock.elapsedTime - previousTime
    if (actions.Track != null) {
      if (preLoad) {
        actions.Track.time = MathUtils.lerp(
          actions.Track.time,
          actions.Track.getClip().duration * data.offset,
          deltaTime
        )
      }
    }
    e.performance.regress()
    previousTime = e.clock.elapsedTime
  })
  useEffect(() => {
    if (!preLoad) {
      actions.Track.loop = LoopOnce
      actions.Track.timeScale = 2
      actions.Track.play()
      actions.Track.getMixer().addEventListener('finished', function () {
        actions.Track.stop()
        actions.Track.timeScale = 1
        actions.Track.play().paused = true
        setPreLoad(true)
        itemDoneById(3)
        actions.Track.getMixer().removeEventListener('finished')
      })
    }
    return function cleanup() {
      if (actions.Track != null) actions.Track.getMixer().removeEventListener('finished')
    }
  }, [actions])
  return (
    <>
      <group ref={ref} dispose={null}>
        <group
          name="Circle"
          position={[0.52, isMobile ? 3.109 : 4.68, -1.59]}
          scale={isMobile ? 6.597 : 3.86}
        >
          <group
            name="Camera"
            position={[0, isMobile ? 0.011 : -0.15, 1.43]}
            rotation={[1.56, 0, 0]}
            scale={[0.26, 0.26, 0.26]}
          >
            <PerspectiveCamera
              ref={cam}
              makeDefault={true}
              far={100}
              near={0.1}
              fov={isMobile ? 20 : 22.9}
              rotation={[-Math.PI / 2, 0, 0]}
            />
          </group>
        </group>
        <mesh
          name="Door"
          geometry={nodes.Door.geometry}
          material={nodes.Door.material}
          position={[0.52, -1.18, -0.47]}
        >
          <a.mesh
            position={springAnimation.position}
            scale={springAnimation.scale}
            onPointerOver={() => onHoverEnter(0)}
            onPointerOut={() => onHoverExit(0)}
            onClick={() => onClick(0)}
            geometry={nodes.Instagram.geometry}
            material={nodes.Instagram.material}
          />
          <a.mesh
            onPointerOver={() => onHoverEnter(1)}
            onPointerOut={() => onHoverExit(1)}
            onClick={() => onClick(1)}
            scale={springAnimation.scale1}
            position={springAnimation.position1}
            geometry={nodes.TikTok.geometry}
            material={nodes.TikTok.material}
            rotation={[-0.02, 0, 0]}
          />
          <a.mesh
            onPointerOver={() => onHoverEnter(3)}
            onPointerOut={() => onHoverExit(3)}
            onClick={() => onClick(3)}
            scale={springAnimation.scale3}
            position={springAnimation.position3}
            geometry={nodes.Twitter.geometry}
            material={nodes.Twitter.material}
          />
          <a.mesh
            onPointerOver={() => onHoverEnter(2)}
            onPointerOut={() => onHoverExit(2)}
            onClick={() => onClick(2)}
            scale={springAnimation.scale2}
            position={springAnimation.position2}
            geometry={nodes.Youtube.geometry}
            material={nodes.Youtube.material}
          />
        </mesh>
        <mesh
          name="FollowUs"
          geometry={nodes.FollowUs.geometry}
          material={nodes.FollowUs.material}
          position={[0.50816, -0.78881, -0.58993]}
          rotation={[-2.99016, 0, 0]}
        />
      </group>
      {/* ------------------- */}
    </>
  )
}
