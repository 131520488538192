import { useGLTF } from '@react-three/drei'
import React, { useEffect } from 'react'
import Button3D from '../model/Button3D'
import model from './../model/button.glb'
import itchio from './../model/logos/itchio.glb'
import oculus from './../model/logos/oculus.glb'
import sidequest from './../model/logos/sidequest.glb'
import steam from './../model/logos/steam.glb'

// import buttonset from './../model/button-set2.glb'
import PropTypes from 'prop-types'
import { VendingMachineButtonsLabels } from './VendingMachineButtons'
import Custom3DButton from './Custom3DButton'
import { useLoader } from '../store'
import { colors_physical } from '../constants'

export default function VendingMachineButtonLoader({ onClick }) {
  const buttons2 = useGLTF('/model/Buttons2.gltf')
  const itchioGLB = useGLTF(itchio)
  const oculusGLB = useGLTF(oculus)
  const sidequestGLB = useGLTF(sidequest)
  const steamGLB = useGLTF(steam)
  const { itemDoneById } = useLoader()
  const { nodes: buttonNodes, materials: buttonMaterials } = useGLTF(model)
  // const buttons2 = useGLTF(buttonset)

  useEffect(() => {
    if (itchioGLB != null && oculusGLB != null && sidequestGLB != null && steamGLB) {
      itemDoneById(2)
    }
  }, [itchioGLB, oculusGLB, sidequestGLB, steamGLB])

  useEffect(() => {
    console.log(buttons2.nodes)
    if (buttons2 != null) {
      itemDoneById(1)
    }
  }, [buttons2])

  return (
    <>
      {buttonNodes != null && buttonMaterials != null && buttons2 != null && (
        <>
          <Button3D
            data={VendingMachineButtonsLabels.label1}
            nodes={buttonNodes}
            materials={buttonMaterials}
            onClick={onClick}
            color={colors_physical.sidequest.color}
            emissive={colors_physical.sidequest.emissive}
            offset={0.06}
            display={sidequestGLB}
            position={[1.34149, 2.4969, -0.43]}
          />
          <Button3D
            data={VendingMachineButtonsLabels.label2}
            nodes={buttonNodes}
            materials={buttonMaterials}
            offset={0.06}
            onClick={onClick}
            color={colors_physical.itchio.color}
            emissive={colors_physical.itchio.emissive}
            display={itchioGLB}
            position={[1.67934, 2.4969, -0.43]}
          />
          <Button3D
            data={VendingMachineButtonsLabels.label3}
            nodes={buttonNodes}
            materials={buttonMaterials}
            offset={0.06}
            onClick={onClick}
            color={colors_physical.oculus.color}
            emissive={colors_physical.oculus.emissive}
            display={oculusGLB}
            position={[1.34149, 2.16025, -0.43]}
          />
          <Button3D
            data={VendingMachineButtonsLabels.label4}
            nodes={buttonNodes}
            materials={buttonMaterials}
            offset={0.06}
            onClick={onClick}
            color={colors_physical.steam.color}
            emissive={colors_physical.steam.emissive}
            display={steamGLB}
            position={[1.67934, 2.16025, -0.43]}
          />
          <Custom3DButton
            data={VendingMachineButtonsLabels.label5}
            onClick={onClick}
            geo={buttons2.nodes.Cube003.geometry}
            material={buttons2.materials.Material}
            position={[0.28363, 3.476, -0.43811]}
            highlightColor={'#AF0000'}
            animationEnd={-0.055}
          />
          <Custom3DButton
            data={VendingMachineButtonsLabels.label6}
            onClick={onClick}
            geo={buttons2.nodes.Cube057.geometry}
            material={buttons2.materials.Material}
            position={[0.87267, 3.476, -0.43811]}
            highlightColor={'#FF0000'}
            animationEnd={-0.055}
          />

          <Custom3DButton
            data={VendingMachineButtonsLabels.label7}
            onClick={onClick}
            geo={buttons2.nodes.Discord.geometry}
            material={buttons2.materials.Material}
            position={[1.327, 0.72666, -1.74209]}
            rotation={[0, 0, 0]}
            scale={1}
            highlightColor={'#0000FF'}
            animationEnd={0.045}
          />
        </>
      )}
    </>
  )
}

VendingMachineButtonLoader.propTypes = {
  onClick: PropTypes.func,
}
