import { Instance, Instances, useDetectGPU, useGLTF } from '@react-three/drei'
import React, { Suspense, useEffect, useRef, useState } from 'react'
import { useLoader } from '../store'
import PropTypes from 'prop-types'
import { useFrame } from '@react-three/fiber'

const range = 500
const xDisplacement = 0.453
const yDisplacement = 0.546
const zDisplacement = 0.13

// let swingMultiplier = 800

const dataMaker = () => {
  let arr = []
  let ctr = 0
  let horz = 0
  let vert = 0
  let dep = 0
  for (let j = 0; j < range; j++) {
    if (ctr >= 16) ctr = 0
    if (horz >= 4) {
      horz = 0
      vert++
    }
    if (vert >= 4) {
      vert = 0
      dep++
    }

    let rand = Math.random()
    let targetRotation = [0, 0, 0]
    // let shouldSwing = false
    if (rand < 0.4) {
      targetRotation = [0, 0, (Math.PI / 25) * (rand - 0.5)]
      // shouldSwing = true
    }

    let rand2 = Math.random()
    arr.push({
      position: [
        0.672 - xDisplacement * horz,
        1.514 + yDisplacement * vert,
        -0.574 - zDisplacement * dep,
      ],
      rotation: targetRotation,
      shouldSwing: false,
      randomKey: (rand2 - 0.5) / 4,
    })
    horz++
    ctr++
  }
  return arr
}

const data = dataMaker()
export default function InstancedToys() {
  return (
    <Suspense fallback={null}>
      <Toys />
    </Suspense>
  )
}

function Toys({ toyRange = 48 }) {
  const glb = useGLTF('/model/ToyYellow.gltf')
  const { itemDoneById } = useLoader()
  const [loaded, setLoaded] = useState(false)
  const GPUTier = useDetectGPU()

  useEffect(() => {
    if (!loaded) {
      setLoaded(true)
      itemDoneById(4)
    }
  }, [glb])

  return (
    <>
      <group>
        <Instances
          range={toyRange}
          material={glb.materials.Material}
          geometry={glb.nodes.Box003.geometry}
        >
          {data.map((props, i) => (
            <Toy key={i} {...props} GPUTier={GPUTier} />
          ))}
        </Instances>
        <Instances
          range={toyRange}
          material={glb.materials['Material.001']}
          geometry={glb.nodes.Plastic.geometry}
        >
          {data.map((props, i) => (
            <ToyBox key={i} {...props} GPUTier={GPUTier} />
          ))}
        </Instances>
      </group>
    </>
  )
}

Toys.propTypes = {
  toyRange: PropTypes.number,
}

function ToyBox(props) {
  const group = useRef()

  useFrame(() => {
    // if (
    //   props.shouldSwing &&
    //   props.GPUTier.tier != '0' &&
    //   props.GPUTier.tier != '1' &&
    //   !props.GPUTier.isMobile
    // ) {
    //   group.current.rotation.z =
    //     Math.sin(state.clock.elapsedTime) / (swingMultiplier * props.randomKey)
    // }
  })

  return (
    <group ref={group} {...props}>
      <Instance />
    </group>
  )
}

ToyBox.propTypes = {
  shouldSwing: PropTypes.bool,
  randomKey: PropTypes.number,
  GPUTier: PropTypes.object,
}

function Toy(props) {
  const group = useRef()
  useFrame(() => {
    // if (
    //   props.shouldSwing &&
    //   props.GPUTier.tier != '0' &&
    //   props.GPUTier.tier != '1' &&
    //   !props.GPUTier.isMobile
    // ) {
    //   group.current.rotation.z =
    //     Math.sin(state.clock.elapsedTime) / (swingMultiplier * props.randomKey)
    // }
  })

  return (
    <group ref={group} {...props}>
      <group>
        <Instance />
      </group>
    </group>
  )
}

Toy.propTypes = {
  shouldSwing: PropTypes.bool,
  randomKey: PropTypes.number,
  GPUTier: PropTypes.object,
}
