import React from 'react'

export default function NotFound() {
  return (
    <div className="floater" style={{ padding: '5vw', height: '100%' }}>
      <div style={{ color: 'white', fontSize: 50, fontFamily: 'Roboto' }}>404</div>
      <div style={{ color: 'white', fontSize: 18, fontFamily: 'Roboto' }}>Page Not Found</div>
    </div>
  )
}
