import React, { useEffect, useState } from 'react'
import { title, version } from './hudContent'
import PropTypes from 'prop-types'
import { css, StyleSheet } from 'aphrodite'
import { usePerf } from 'r3f-perf'
/*
WARNING: Using logEnabled causes the experience to have a memory leak and lag over time, please use it sparingly
*/

export default function Hud({ logEnabled = false, enabled = false }) {
  const { gl, log } = usePerf()
  const [reso, setReso] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  })
  useEffect(() => {
    window.addEventListener('resize', resizing)
    return function cleanup() {
      window.removeEventListener('resize', resizing)
    }
  }, [window])
  function resizing() {
    setReso({ width: window.innerWidth, height: window.innerHeight })
  }
  return (
    <>
      {enabled && (
        <>
          <div className={css(styles.container)}>
            <div className={css(styles.title)}>{title}</div>
            <div className={css(styles.subtitle)}>{version}</div>
            <div className={css(styles.info)}>
              <b>Window:</b> {reso.width} x {reso.height}
            </div>
            {logEnabled && log != null && gl != null && (
              <>
                <div className={css(styles.info)}>
                  <b>FPS:</b> {log.fps}
                </div>
                <div className={css(styles.info)}>
                  <b>CPU:</b> {log.cpu.toFixed(3)}
                </div>
                <div className={css(styles.info)}>
                  <b>GPU:</b> {log.gpu.toFixed(3)}
                </div>
                <div className={css(styles.info)}>
                  <b>Textures:</b> {gl.memory.textures}
                </div>
                <div className={css(styles.info)}>
                  <b>Calls:</b> {gl.render.calls}
                </div>
              </>
            )}
          </div>
        </>
      )}
    </>
  )
}

Hud.propTypes = {
  logEnabled: PropTypes.bool,
  enabled: PropTypes.bool,
}

const styles = StyleSheet.create({
  container: {
    pointerEvents: 'none',
    position: 'absolute',
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    padding: 24,
  },
  title: {
    fontSize: 40,
    color: '#00000051',
  },
  subtitle: {
    fontSize: 20,
    color: '#00000051',
  },
  info: {
    fontSize: 10,
    color: '#00000051',
  },
})
