import { Text } from '@react-three/drei'
import React from 'react'
import { content } from '../constants'

export default function FAQSection() {
  return (
    <>
      <group position={[1.72, 2.48, -1.76]} scale={[-1, 1, 1]}>
        <Text
          anchorX="left"
          anchorY="top"
          fontSize={0.075}
          textAlign="left"
          color="black"
          font="/font/OpenSans/OpenSans-Bold.ttf"
        >
          FAQ
        </Text>
      </group>
      <group position={[1.72, 2.32, -1.76]} scale={[-1, 1, 1]}>
        {content.map((data, index) => (
          <group key={index}>
            <group position={[0, -0.31 * index + (data.top != null ? data.top : 0), 0]}>
              <Text
                anchorX="left"
                anchorY="top"
                fontSize={0.047}
                font="/font/OpenSans/OpenSans-Bold.ttf"
                lineHeight={1.07}
                textAlign="left"
                color="black"
                maxWidth={1}
              >
                {data.question}
              </Text>
              <group position={[0, -0.065, 0]}>
                <Text
                  anchorX="left"
                  anchorY="top"
                  fontSize={0.047}
                  font="/font/OpenSans/OpenSans-Regular.ttf"
                  lineHeight={1.07}
                  textAlign="left"
                  color="black"
                  maxWidth={1}
                >
                  {data.answer}
                </Text>
              </group>
            </group>
          </group>
        ))}
      </group>
    </>
  )
}
