import { css, StyleSheet } from 'aphrodite'
import React, { useEffect } from 'react'
// import LoadingSpinner from './LoadingSpinner'
import { useLoader } from '../store'
import Loader from './Loader'
import { colors } from '../content'
import { startMeasuringDownloadTime } from '../helper/google-analytics'
// import { MathUtils } from 'three'
export default function SplashScreen() {
  const { doneAmount, startLoading } = useLoader()
  useEffect(() => {
    console.log(doneAmount)
    startMeasuringDownloadTime()
    startLoading()
  }, [])

  return (
    <Loader
      loadingBar={false}
      loading={<div></div>}
      done={<div></div>}
      content={
        <div className="dyn-gradient" style={{ height: '100%', paddingTop: '10vh' }}>
          <div>
            {/* <div className={css(localStyles.responsiveSpinner)}>
              <div
                style={{ position: 'absolute', left: '50%', transform: ' translate(-50%,-50%)' }}
              >
                <LoadingSpinner />
              </div>
            </div> */}
            <div className={css(localStyles.loaderContainer)}>
              <img
                style={{
                  position: 'absolute',
                  opacity: 0.3,
                  width: '25vw',
                  height: 'auto',
                }}
                src="/logo.webp"
                alt="district m"
              />
              <img
                className="animatedClipPathLoader"
                style={{
                  '--maskX': ((100 - doneAmount) / 100) * 25 + 'vw',
                  width: '25vw',
                  height: 'auto',
                }}
                src="/logo.webp"
                alt="district m"
              />
            </div>
            <div
              style={{
                marginTop: '5vw',
                textAlign: 'center',
                fontSize: 28,
                color: colors.darkBlue,
                fontWeight: 'bold',
              }}
            >
              LOADING
            </div>
            <div
              style={{
                textAlign: 'center',
                fontSize: 16,
                marginTop: '1vh',
                color: colors.darkBlue,
              }}
            >
              This may take a few moments
            </div>
          </div>
        </div>
      }
    />
  )
}

const localStyles = StyleSheet.create({
  loaderContainer: {
    width: '100vw',
    textAlign: 'center',
    minHeight: '30vh',
  },
  responsiveSpinner: {
    pointerEvents: 'none',
    paddingTop: '30vh',
    transform: 'scale(0.4)',
    '@media (min-width: 576px)': {
      transform: 'scale(0.4)',
    },
    '@media (min-width: 768px)': {
      transform: 'scale(0.4)',
    },
    '@media (min-width: 992px)': {
      transform: 'scale(0.6)',
    },
    '@media (min-width: 1200px)': {
      transform: 'scale(0.7)',
    },
    '@media (min-width: 2000px)': {
      transform: 'scale(1)',
    },
  },
})

// function useInterval(callback, delay) {
//   const savedCallback = useRef(callback)

//   // Remember the latest callback if it changes.

//   useLayoutEffect(() => {
//     savedCallback.current = callback
//   }, [callback])

//   // Set up the interval.

//   useEffect(() => {
//     // Don't schedule if no delay is specified.

//     // Note: 0 is a valid value for delay.

//     if (!delay && delay !== 0) {
//       return
//     }

//     const id = setInterval(() => savedCallback.current(), delay)

//     return () => clearInterval(id)
//   }, [delay])
// }
