import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useSpring } from '@react-spring/core'
import { animated } from '@react-spring/three'
import { DoubleSide } from 'three'

export default function Custom3DButton({
  geo,
  material,
  position,
  highlightColor,
  scale,
  animationEnd,
  rotation,
  onClick: clickReport,
  data,
}) {
  const [active, setActive] = useState(false)
  const [highlight, setHighlight] = useState(false)
  const animation = useSpring({
    position: active ? [0, 0, animationEnd] : [0, 0, 0],
    highlight: highlight ? 1 : 0,
  })

  function onClick() {
    console.log(material)
    clickReport(data)
    setActive(true)
    setTimeout(() => {
      setActive(false)
    }, 250)
  }

  function onHover(stat) {
    setHighlight(stat)
    document.body.style.cursor = stat ? 'pointer' : 'default'
  }

  return (
    <>
      <group
        position={position}
        onClick={onClick}
        onPointerOver={() => onHover(true)}
        onPointerOut={() => onHover(false)}
      >
        <animated.mesh
          geometry={geo}
          scale={scale != null ? scale : 1}
          rotation={rotation != null ? rotation : [0, 0, 0]}
          position={animation.position}
        >
          <animated.meshStandardMaterial
            attach="material"
            map={material.map}
            color={material.color}
            side={DoubleSide}
            emissive={highlightColor}
            emissiveIntensity={animation.highlight}
            roughness={material.roughness}
            metalness={material.metalnes}
          />
        </animated.mesh>
      </group>
    </>
  )
}

Custom3DButton.propTypes = {
  geo: PropTypes.object,
  material: PropTypes.object,
  position: PropTypes.array,
  highlightColor: PropTypes.string,
  scale: PropTypes.number,
  animationEnd: PropTypes.number,
  rotation: PropTypes.array,
  onClick: PropTypes.func,
  data: PropTypes.object,
}
