import { useGLTF } from '@react-three/drei'
import React, { Suspense, useEffect } from 'react'
import { useLoader } from '../store'

function VendingMachineModel() {
  let gltf = useGLTF('model/Environment.gltf')
  const { itemDoneById } = useLoader()
  useEffect(() => {
    itemDoneById(0)
  }, [gltf])
  return <primitive object={gltf.scene} dispose={null} />
}

export default function VendingMachine() {
  return (
    <Suspense fallback={null}>
      <VendingMachineModel />
    </Suspense>
  )
}
