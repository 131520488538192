import React from 'react'
import { VideoTexture } from './VideoTexture'

export default function WebsiteHeader() {
  return (
    <>
      <group position={[0.5, 4.15, -0.445]}>
        <VideoTexture />
      </group>
    </>
  )
}
