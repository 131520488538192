import React, { Suspense } from 'react'
import { sendEvent } from '../helper/google-analytics'
import VendingMachineButtonLoader from './VendingMachineButtonLoader'

export const VendingMachineButtonsLabels = {
  label1: {
    name: 'sidequest',
    url: '',
  },
  label2: {
    name: 'itchio',
    url: '',
  },
  label3: {
    name: 'oculus',
    url: '',
  },
  label4: {
    name: 'steam',
    url: '',
  },
  label5: {
    name: 'buy',
    url: '',
  },
  label6: {
    name: 'trailer',
    url: '',
  },
  label7: {
    name: 'discord',
    url: '',
  },
}

let timeout = null

export default function VendingMachineButtons() {
  function onClick(item) {
    if (timeout != null) return

    timeout = setTimeout(() => {
      sendEvent('click', 'conversion-button', item.name)
      if (item.url != '') {
        window.open(item.url, '_blank')
      } else {
        console.log('URL not specified')
        // window.alert('URL not specified')
      }

      clearTimeout(timeout)
      timeout = null
    }, 1200)
  }
  return (
    <Suspense fallback={null}>
      <VendingMachineButtonLoader onClick={onClick} />
    </Suspense>
  )
}
