export const logEnabled = false
export const gaDeveloper = true

export const gaConfig = {
  enabled: true,
  code: 'G-PX8WMKF737',
}

export const colors_physical = {
  sidequest: {
    color: '#B157FF',
    emissive: '#C789FC',
  },
  itchio: {
    color: '#FFDE00',
    emissive: '#FFF08D',
  },
  oculus: {
    color: '#C68B5E',
    emissive: '#C68B5E',
  },
  steam: {
    color: '#FF2694',
    emissive: '#FF75BB',
  },
  vendingMachineLight: {
    color: 'hotpink',
  },
  fog: {
    color: '#00A8FF',
  },
}

export const content = [
  {
    question: 'What is Early Access?',
    answer:
      'The game is still in development, so we`ll be adding more features and levels to the game as we build up to a full launch in mid-2022.',
  },
  {
    question: 'Why should I buy the game now?',
    top: 0.05,
    answer:
      'You`ll be one of the first few people to try an awesome new VR mechanic and your feedback will help us build a better District M. Buy the game at $10 right now and in return for your support, you`ll receive the full game at launch at no extra cost.',
  },
  {
    question: 'What headsets can I play this on?',
    answer:
      'For now, it`s on the Oculus Quest and Quest 2. We plan on launching soon on all other platforms and headsets.',
    top: -0.05,
  },
  {
    question: 'How do I stay updated?',
    answer:
      'Follow us on twitter for regular updates, or come over to our discord and join us as we build a community that loves this feel good vr skater :)',
    top: 0,
  },
]

// Tween points
export const TweenPoints = {
  camPosition: {
    position: [-10, 10, -15],
    control: [0, 0, 0],
    name: 'cubePositionName2',
  },
}
