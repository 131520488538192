import { css, StyleSheet } from 'aphrodite'
import React from 'react'
import { sendEvent } from '../helper/google-analytics'
import { useCustomScroll } from '../store'

export default function WebHud() {
  const { setRequestedValue } = useCustomScroll()
  function scrollTo(value) {
    // window.scrollTo(0, value)
    setRequestedValue(value)
  }
  return (
    <div className={css(styles.floater, styles.container)}>
      <div className={css(styles.logoHolder)}>
        {/* <img className={css(styles.logo)} src="./logo.png" alt="DistrictM" /> */}
        <div className={css(styles.buttonHolder)}>
          <div
            className={css(styles.buttonImage)}
            onClick={() => {
              sendEvent('click', 'nav-button', 'Contact-Us')
            }}
          >
            <img
              className={css(styles.contactImage)}
              src="./images/ContactUsButton.png"
              alt="contact-us"
            />
          </div>
          <div
            className={css(styles.button)}
            onClick={() => {
              sendEvent('click', 'nav-button', 'FAQ')
              scrollTo(0.535)
            }}
          >
            FAQ
          </div>
          <div
            className={css(styles.button)}
            onClick={() => {
              sendEvent('click', 'nav-button', 'Social')
              scrollTo(1)
            }}
          >
            Social
          </div>
        </div>
      </div>
    </div>
  )
}

const styles = StyleSheet.create({
  footer: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    fontSize: '2.5vh',
    height: '6vh',
    paddingTop: '1.25vh',
    textAlign: 'center',
  },
  floater: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    pointerEvents: 'none',
  },
  container: {
    zIndex: 100,
  },
  logoHolder: {
    padding: '1vw',
  },
  logo: {
    height: '10vw',
  },
  buttonImage: {
    pointerEvents: 'all',
    color: 'white',
    float: 'right',
    ':hover': {
      cursor: 'pointer',
      transform: 'scale(1.1)',
    },
  },
  contactImage: {
    width: '13vh',
    '@media (min-width: 576px)': {
      width: '20vh',
    },
    '@media (min-width: 768px)': {
      width: '12vh',
    },
    '@media (min-width: 1200px)': {
      width: '10vw',
    },
  },
  button: {
    pointerEvents: 'all',
    fontFamily: 'Open Sans',
    fontWeight: 700,
    fontSize: '2.2vh',
    color: 'white',
    float: 'right',
    margin: '1.5vh',
    padding: '1vh',
    marginRight: '1.5vw',
    ':hover': {
      cursor: 'pointer',
      transform: 'scale(1.1)',
    },

    '@media (min-width: 576px)': {
      marginTop: '3vh',
      fontSize: '3vh',
    },
    '@media (min-width: 768px)': {
      marginTop: '1vh',
      fontSize: '2.2vh',
    },
    '@media (min-width: 1200px)': {
      marginTop: '3vh',
      fontSize: '2.2vh',
    },
  },
  buttonHolder: {
    position: 'absolute',
    right: 0,
    top: 0,
    height: '6vw',
    paddingRight: '2vw',
    paddingTop: '1vw',
    width: '90vw',
  },
})
