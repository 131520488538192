import { css, StyleSheet } from 'aphrodite'
import React from 'react'
import PropTypes from 'prop-types'

export default function LoadingScreen({ state, loading, done, content }) {
  return (
    <>
      <div className={css(styles.free)}>{content}</div>
      <div className={css(styles.free)}>
        {state.isLoading && <>{loading}</>}
        {!state.hasError && state.isDone && <>{done}</>}
      </div>
    </>
  )
}

const styles = StyleSheet.create({
  free: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
})

LoadingScreen.propTypes = {
  state: PropTypes.object,
  loading: PropTypes.element,
  done: PropTypes.element,
  content: PropTypes.element,
}
