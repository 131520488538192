import ReactGA from 'react-ga4'

export function activateGA(id) {
  ReactGA.initialize(id)
}

/**
 *
 * @param {*} action The value that will appear as the event action in Google Analytics Event reports.
 * @param {*} category Default Value "(not set)" The value that will appear as the event action in Google Analytics Event reports.
 * @param {*} label Default Value "(not set)" The value that will appear as the event action in Google Analytics Event reports.
 */
export function sendEvent(action, category, label) {
  window.gtag('event', action, {
    event_category: category,
    event_label: label,
  })
}

/**
 * Manually send page_view events
 * @param {*} pageTitle The title of the page.
 * @param {*} pageLocation 	The URL of the page.
 * @param {*} pagePath The path to the page. If overridden, this value must start with a '/' character.
 *
 */
export function sendPageView(pageTitle, pageLocation, pagePath) {
  window.gtag('event', 'page_view', {
    page_title: pageTitle,
    page_location: pageLocation,
    page_path: pagePath,
  })
}

export function setDeveloperStatus() {
  window.gtag('set', { is_developer: true })
}

let time = null

export function sendDownloadTime(time) {
  window.gtag('event', 'download-time', { value: time })
}

export function startMeasuringDownloadTime() {
  time = new Date()
}

export function stopMeasuringDownloadTime() {
  let timeNow = new Date()
  let diff = timeNow - time
  sendDownloadTime(diff / 1000)
}
